@import '~antd/dist/antd.css';

.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}

#body-layout.ant-layout {
  min-height: calc(100vh - 68px);
}

.no-padding .ant-collapse-content-box {
  padding: 0;
}
