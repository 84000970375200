/* Component styling */
.navbar {
  margin-top: 5px;
  margin-bottom: 3px;
  width: 100%;
  height: 65px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.navbar-logo {
  width: 128px;
  float: left;
  margin-left: 10px;
}

.navbar-container .navbar-left {
  width: 40%;
  height: 65px;
  min-width: 400px;
  flex-wrap: nowrap;
}

.navbar-container .navbar-right {
  width: 60%;
  height: 65px;
}

/* Menu Ant Design overrides */
.navbar-container .ant-menu-horizontal {
  border-bottom: none;
}
.navbar-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: 130px;
}

.navbar-mobile-button {
  display: none !important; /* use of important to overwrite ant-btn */
  background: #3e91f7;
}

.navbar-drawer .ant-drawer-body {
  padding: 0 !important;
}

/* align header of Drawer with header of page */
.navbar-drawer .ant-drawer-header {
  padding: 12px 24px !important;
}

@media (max-width: 825px) {
  .navbar-mobile-button {
    display: inline-flex !important;
    float: right;
    margin-top: 15px;
  }

  .navbar-right {
    display: none;
  }

  .navbar-logo a {
    margin-left: -16px;
  }

  .navbar-container .ant-menu-item,
  .navbar-container .ant-menu-submenu-title {
    padding: 1px 12px;
  }

  .navbar-logo a {
    padding: 8px 16px;
  }
}

.badge .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 1px 1px #d9d9d9 inset;
}

.ant-menu-item:hover button:hover {
  background-color: #fff;
}

.ant-menu-item:hover .ant-badge .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 1px 1px #d9d9d9 inset;
}
